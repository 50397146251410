<template>
    <div>
        <breadcrumbs :pages="[{ title: 'Home' }, { title: 'Risks', isActive: true  }]"></breadcrumbs>
        <b-row class="justify-content-md-center my-5">
            <b-col md="12" offset-md="4">
                <h2 class="mb-4">Risks of using xSigma</h2>
            </b-col>
            <b-col md="8" class=" risks-content">
                <p><strong>xSigma DeFi Risk Disclaimer</strong></p>
<p>Thank you for visiting <a href="https://xsigma.fi">https://xsigma.fi</a> (the “<strong>Site</strong>”).</p>
<p>Please read this disclaimer prior to accessing the xSigma DeFi Protocol (the “<strong>xSigma Protocol</strong>”) as it contains important warnings to users concerning the and xSigma Protocol of which you must be aware prior to engaging with it.</p>
<p>The xSigma Protocol is published to the Ethereum blockchain by xSigma Corporation, a British Virgin Islands corporation, having its registered address at OMC Chambers, Wickhams Cay 1, Road Town, Tortola, British Virgin Islands (“xSigma”, “we”, “us” or “our”).</p>
<p><strong>BY ENGAGING WITH THE XSIGMA PROTOCOL, WITHOUT LIMITATION, YOU ASSUME THE RISKS LISTED HERE.</strong></p>
<p><strong>Information Risk</strong></p>
<p>This website will collect and display crypto currency market and other information but xSigma makes no warranty as to its accuracy, completeness and timeliness of such information and does not take any responsibility for it being incorrect or for your own errors or decisions.</p>
<p><strong>Investment Risk</strong></p>
<p>The Site does not supply any investment advice, financial advice, trading advice or any other type of advice, you should not interpret such information as any investment advice, or as a recommendation for you to trade or hold any decentralized finance (“<strong>DeFi</strong>”) assets or other digital assets.</p>
<p>YOU CAN LOSE ALL OF YOUR ASSETS IF YOUR PRIVATE KEYS ARE COMPROMIZED.  ANY VALUE THAT YOU INCLUDE IN THE XSIGMA PROTOCOL CAN BE LOST ENTIRELY IF YOU LOSE YOUR PRIVATE KEYS AND IT MAY ALSO BECOME INACCCESSIBLE IF GAS FEES, ETHETERUM NETWORK FAULURE OR OTHER INTERVEING FACTORS REDER RETRIEVAL OF YOUR ASSETS IMPRACTICAL OR IMPOSSIBLE.</p>
<p><strong>Other Risks</strong></p>
<p>DeFi protocols, including the xSigma Protocol, are highly risky.  Before engaging with the xSigma Protocol, you must fully assess your risk tolerance, and look for professional investment advice from your financial advisor.</p>
<ol>
<li><em>Project credit risk</em>: Some DeFi projects are fraudulent; it is necessary to be aware of risk of projects being a fraudulent or otherwise malicious. The xSigma is not a fraud.</li>
<li><em>Risk of project&#39;s being unsustainable</em>: DeFi as a new concept, is still facing different degrees of limitations in terms of scalability, availability and liquidity, etc., users need to be aware of unsustainable nature of some DeFi project themselves (such as Ethereum network congestion, high GAS fees, high interest rates, high mining fees to maintain its growth and unsustainability in the long term). XSigma is subject to this risk;</li>
<li><em>Assets security risk</em>:    you must take into consideration the risk of your assets being lost due to hacks or other vulnerabilities in your wallets or those that you entrust with your assets;</li>
<li><em>Market risk</em>: there is a great risk in DeFi trading, massive price fluctuations, so users need to be aware of market price fluctuations risk, some projects may have the risk of premature liquidation and liquidation under extreme market conditions;</li>
<li><em>Regulatory risk</em>: DeFi is essentially decentralized financial business, facing financial regulatory risk of uncertainty. Regulators may require xSigma to modify the xSigma Protocol if they deem it to be not in compliance with applicable rules. xSigma will likely not have the necessary voting rights in the xSigma Protocol to give effect to such requirements, which may permanently hinder the project;</li>
<li><em>Systemic risk</em>: DeFi products and other products are interlinked, once a link collapses, the entire ecosystem may form a domino effect, facing the risk of whole system collapse;</li>
<li><em>Money laundering risk</em>: a DeFi protocol such as the xSigma Protocol is public by its very nature and does not screen the owners of wallets that interact with it. The xSigma Protocol may be abused by criminals and money launderers and xSigma may lack the <em>de facto</em> ability to stop them or even identify them; and</li>
<li>Other unforeseen risks: other related risks that may be encountered, such as the risk of asset loss due to unknown rules of the project.</li>
</ol>
<p><strong>Third Party Integrations</strong></p>
<p>The Site may be integrated with third party sites but xSigma does not endorse, guarantee, promise or recommend any information, products and services provided by any third-party website in its website or hyperlinks to any third-party website and any other form of content that does not belong to the subject of this website;</p>
<p>You acknowledge that you retain control of your digital assets at all times via your private keys that only you control.</p>
<p><strong>Legal Disclosure</strong></p>
<p>Your engagement with the xSigma Protocol is also subject to the xSigma Portal Terms of Use, posted here <a href="https://app.xsigma.fi/terms">https://app.xsigma.fi/terms</a> and the xSigma Privacy Policy, posted here <a href="https://app.xsigma.fi/policy">https://app.xsigma.fi/policy</a>, each of which are incorporated herein by reference.</p>
<p>Please consult our documentation and FAQs posted here <a href="https://docs.xsigma.fi/faqs">https://docs.xsigma.fi/faqs</a> for more information.</p>

            </b-col>
        </b-row>
    </div>
</template>

<script>
export default {
    name: "Risks",
    methods: {
        goTo(url) {
            // window.location.href = url
            window.open(url, '_blank')
        }
    }
};
</script>

<style scoped lang="scss">
@import "src/assets/variables";
</style>

